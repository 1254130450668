import Vue from "vue";
import marked from "marked";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import { i18n } from "./i18n";

import axiosInstance from "./helpers/axiosInstance";
import dayjsInstance from "./helpers/dayjsInstance";
import inject from "./helpers/stringInject";
import query from "./helpers/query";

Vue.config.productionTip = false;

dayjsInstance.locale(i18n.locale);

const mdRendererLinksTargetBlank = new marked.Renderer();
mdRendererLinksTargetBlank.link = function (href, title, text) {
  // eslint-disable-line
  const link = marked.Renderer.prototype.link.call(this, href, title, text);
  return link.replace("<a", '<a target="_blank" ');
};
marked.setOptions({
  renderer: mdRendererLinksTargetBlank,
});
Vue.prototype.$marked = marked;

Vue.prototype.$axios = axiosInstance();
Vue.prototype.$dayjs = dayjsInstance;
Vue.prototype.$inject = inject;
Vue.prototype.$query = query;

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
