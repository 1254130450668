const state = {
  todoParcels: [],
};

const mutations = {
  ADD_TODO_PARCEL(newState, todoParcel) {
    const existingIndex = newState.todoParcels.findIndex(
      (parcel) => parcel.ori_parcel_id === todoParcel.parcelID
    );
    if (existingIndex === -1) {
      newState.todoParcels.push(todoParcel);
    } else {
      newState.todoParcels[existingIndex] = todoParcel;
    }
  },
  DELETE_TODO_PARCEL(newState, todoParcel) {
    const existingIndex = newState.todoParcels.findIndex(
      (parcel) => parcel.ori_parcel_id === todoParcel.parcelID
    );
    if (existingIndex > -1) {
      newState.todoParcels.splice(existingIndex, 1);
    }
  },
  SET_TODO_PARCELS(newState, todoParcels) {
    newState.todoParcels = todoParcels;
  },
  SET_PARCEL_STATUS(newState, todoParcel) {
    const existingIndex = newState.todoParcels.findIndex(
      (parcel) => parcel.ori_parcel_id === todoParcel.parcelID
    );
    if (existingIndex > -1) {
      const newParcel = newState.todoParcels[existingIndex];
      newParcel.status = todoParcel.status;
      newState.todoParcels.splice(existingIndex, 1, newParcel);
    }
  },
  SET_PARCEL_COMMENT(newState, todoParcel) {
    const existingIndex = newState.todoParcels.findIndex(
      (parcel) => parcel.ori_parcel_id === todoParcel.parcelID
    );
    if (existingIndex > -1) {
      const newParcel = newState.todoParcels[existingIndex];
      newParcel.comment = todoParcel.comment;
      newState.todoParcels.splice(existingIndex, 1, newParcel);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
