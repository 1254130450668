export default function loadLocales() {
  const locales = {};
  const dayjsLocales = {};
  const messages = {};
  const localesPaths = require.context("../config/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const availableLanguages = window.appConfig.VUE_APP_I18N_LANGUAGES.split(", ");
  localesPaths.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1 && availableLanguages.indexOf(matched[1]) > -1) {
      const locale = matched[1];
      // eslint-disable-next-line
      locales[locale] = require(`vuetify/lib/locale/${locale}.js`).default;
      // eslint-disable-next-line
      dayjsLocales[locale] = require(`dayjs/locale/${locale}.js`);
      messages[locale] = localesPaths(key).local;
    }
  });
  return {
    locales,
    dayjsLocales,
    messages,
  };
}
