import Vue from "vue";
import Vuex from "vuex";
import parcels from "./modules/parcels"; // eslint-disable-line
import todo from "./modules/todo";
import router from "../router/index"; // eslint-disable-line

Vue.use(Vuex);
const parcelsUrl = window.appConfig.VUE_APP_PARCELS_URL;
const parsedParams = (parcelsUrl.match(/([^?=&]+)(=([^&]*))?/g) || []).reduce((result, each) => {
  const [key, value] = each.split("=");
  if (value === undefined) {
    const date = key.match(/\d{4}\/\d{2}\/\d{2}/g)[0];
    result.reference_date = date.split("/").join("-");
  } else if (key.includes("_model_name")) {
    if (!result.available_models) {
      result.available_models = {};
    }
    result.available_models[key] = value;
  } else {
    result[key] = value;
  }
  return result;
}, {});

const store = new Vuex.Store({
  modules: {
    parcels,
    todo,
  },
  state: {
    language: "en",
    appVersion: process.env.PACKAGE_VERSION,
    appName: window.appConfig.VUE_APP_TITLE,
    /** @type { import("../types/common").ParcelConfig */
    parcelConfig: {
      available_models: parsedParams.available_models || {},
      config_date: parsedParams.config_date,
      reference_date: parsedParams.reference_date,
    },
    alert: {
      message: "",
      type: "",
    },
    metaInfo: null,
  },
  mutations: {
    sendAlert(state, payload) {
      state.alert.message = payload.message;
      state.alert.type = payload.type;
    },
    SET_META_INFO(state, metaInfo) {
      Vue.set(state, "metaInfo", metaInfo);
    },
  },
  actions: {
    async fetchMetaInfo({ commit }) {
      let metaInfo;
      try {
        const /** @type { { data: import("../types/MetaInfo").MetaInfo }} */ response =
            await this._vm.$axios.get(window.appConfig.VUE_APP_META_API_URL);
        const {
          products,
          annotation_parameters: annotationParameters,
          layers: availableLayers,
          definitions: availableDefinitions,
          fields,
          image_sources: imageSources,
        } = response.data;

        // parse available field values and make uniform interface
        fields.forEach((field) => {
          if (typeof field.available_values === "string") {
            field.available_values =
              availableDefinitions[field.available_values.replace("definitions/", "")];
          } else if (field.available_values) {
            field.available_values = field.available_values.map((value) => {
              return { [field.field_name]: value };
            });
          }
        });

        // on app initial load, select first layer of current route/product
        const selectedProduct = products.find((p) => router.currentRoute.path.includes(p.id));
        let firstLayerId;
        if (selectedProduct) {
          [firstLayerId] = selectedProduct.layers;
        } else {
          [firstLayerId] = products[0].layers;
        }
        const propertiesWhitelist = availableLayers.find(
          (l) => l.id === firstLayerId
        ).available_properties;

        const keyBy = (array, key) =>
          (array || []).reduce(
            (r, x) => ({
              ...r,
              [key ? x[key] : x]: x,
            }),
            {}
          );

        // these lookups are part of understanding API and may not be renamed!
        const cropToGroupLookup = keyBy(availableDefinitions.crops, "ori_crop_id");
        const groupToColorLookup = keyBy(availableDefinitions.groups, "id");
        const oriCropIdLookup = keyBy(availableDefinitions.crops, "ori_crop_id");
        const groupIdLookup = keyBy(availableDefinitions.groups, "id");
        const oriGroupIdLookup = keyBy(availableDefinitions.groups, "ori_group_id");

        metaInfo = {
          products,
          availableLayers,
          propertiesWhitelist,
          lookups: {
            cropToGroupLookup,
            groupToColorLookup,
            oriCropIdLookup,
            groupIdLookup,
            oriGroupIdLookup,
          },
          annotationParameters,
          fields,
          imageSources,
        };

        commit("SET_META_INFO", metaInfo);
      } catch (error) {
        console.log(error);
      }
      return metaInfo;
    },
  },
  getters: {
    parcelConfig: (state) => state.parcelConfig,
  },
});

export default store;
