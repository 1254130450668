import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import loadLocales from "./helpers/loadLocales";
import loadOverride from "./helpers/loadOverride";

Vue.use(VueI18n);
const loadedLocales = loadLocales();

// check if locale setting in local storage
const storageLocale = localStorage.getItem("locale");

let browserLocale = navigator.language || navigator.userLanguage;
if (browserLocale) {
  [browserLocale] = browserLocale.split("-");
}
// if not allowed by deployment config, ignore browser language
if (!Object.keys(loadedLocales.locales).includes(browserLocale)) {
  browserLocale = null;
}

export const i18n = new VueI18n({
  locale:
    storageLocale ||
    browserLocale ||
    window.appConfig.VUE_APP_I18N_LANGUAGES.split(", ")[0] ||
    "en",
  fallbackLocale: "en",
  messages: loadedLocales.messages,
});

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const overrides = await loadOverride(`locales/${lang}.json`);

  // If the language hasn't been loaded yet
  return axios
    .get(window.appConfig.VUE_APP_I18N_TRANSLATIONS_API_URL, {
      headers: {
        "Accept-Language": lang,
      },
    })
    .then((messages) => {
      const auxMessages = {};
      Object.assign(auxMessages, i18n.messages);
      auxMessages[lang] = {
        ...loadedLocales.messages[lang],
        ...messages.data.catalog,
        ...(overrides?.local ?? {}),
      };
      i18n.setLocaleMessage(lang, auxMessages[lang]);
      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
}

loadLanguageAsync(i18n.locale);
