import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticStyle:{"z-index":"19"},attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"primary","dark":""}},[(!_vm.hideMenu)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer.apply(null, arguments)}}}):_vm._e(),_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$t(_vm.$store.state.parcels.selectedProduct))+" | "+_vm._s(_vm.$store.state.appName)+" ")]),_c(VSpacer),(_vm.userInfo)?_c(VMenu,{attrs:{"transition":"slide-x-transition","bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"text-none",attrs:{"text":_vm.$vuetify.breakpoint.smAndUp,"icon":_vm.$vuetify.breakpoint.xsOnly}},on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm.userInfo.email))]):_vm._e(),_c(VIcon,{attrs:{"right":_vm.$vuetify.breakpoint.smAndUp,"color":"white"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,2180464986)},[_c(VList,[_c(VListItem,{attrs:{"width":"200"}},[_c(VListItemAvatar,{staticClass:"my-0"},[_c(VIcon,[_vm._v(" mdi-account-circle ")])],1),_c(VListItemContent,{staticClass:"d-block"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.userInfo.user))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.userInfo.email))])],1)],1),_c(VListItem,{attrs:{"href":_vm.logoutUrl}},[_c(VListItemAvatar,{staticClass:"my-0"},[_c(VIcon,[_vm._v(" mdi-logout-variant ")])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")])],1),_c(VDivider),_c(VListItem,[_c(VListItemAvatar,{staticClass:"my-0"},[_c(VIcon,[_vm._v(" mdi-information-outline ")])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t("app-version"))+" : "+_vm._s(_vm.$store.state.appVersion)+" ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }