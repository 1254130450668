<template>
  <div>
    <v-select
      v-model="$i18n.locale"
      :items="langs"
      hide-details
      dense
      class="mt-0"
      :disabled="langs.length < 2"
    />
  </div>
</template>

<script>
import { loadLanguageAsync } from "../i18n";

export default {
  computed: {
    langs() {
      return window.appConfig.VUE_APP_I18N_LANGUAGES.split(", ");
    },
  },
  watch: {
    "$i18n.locale": function i18nLocale(newLocale) {
      this.$vuetify.lang.current = newLocale;
      this.$dayjs.locale(newLocale);
      localStorage.setItem("locale", newLocale);
      loadLanguageAsync(newLocale);
    },
  },
};
</script>
