import axios from "axios";

/**
 * @callback LoadOverride
 * @param {string} path
 * @param {string} prefix
 * @returns { Promise }
 */

/** @type {LoadOverride} */
export default async function loadOverride(path, prefix = "/overrides/") {
  let override;

  try {
    override = (await axios.get(prefix + path)).data;
    console.log(`${path} overridden`);
  } catch (error) {
    if (error.response.status === 404) {
      console.info(`No overrides for ${path} exist.`);
    } else {
      console.error(error);
    }
  }

  return override;
}
