/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/** @typedef { import("../../types/MetaInfo").StoreMetaInfo } StoreMetaInfo */
/** @typedef { import("../../types/MetaInfo").ImageSource } ImageSource */
/** @typedef { import("../../types/common").DateString } DateString */
/** @typedef { import("../../types/store").SelectedParcelAvailableImageDates } SelectedParcelAvailableImageDates */
/** @typedef { import("../../types/ParcelImage").Visualization } Visualization */
/** @typedef {{
 *  selectedParcelAvailableImageDates: SelectedParcelAvailableImageDates
 *  selectedDate: DateString?
 * }} State */
/** @typedef {{
 *  alternativeWMSSourceActivated: boolean
 *  alternativeSignalSourceActivated: boolean
 *  activatedImageSources: ImageSource[]
 * }} Getters */

import Vue from "vue";
import dayjs from "../../helpers/dayjsInstance";

const isBetween = require("dayjs/plugin/isBetween");

dayjs.extend(isBetween);

const state = {
  selectedProduct: null,
  selectedLayer: null,
  selectedParcel: null,
  selectedDate: null,
  selectedParcelAvailableImageDates: {},
  selectedReferenceParcels: null,
  selectedReferenceArea: null,
  selectedParcelFilter: null,
  allMapFeatures: null,
  currentMapFeatures: null,
  exportApprovals: [],
  mapLoading: null,
  cropCoverPeriods: [],
  markerTableLoading: false,
};

const getters = {
  alternativeWMSSourceActivated(state, getters, rootState) {
    const parcelEligible =
      state.selectedParcel && state.selectedParcel.get(window.appConfig.VUE_APP_ACTIVATOR_PROPERTY);
    const activatedAvailable = rootState.metaInfo.imageSources.length > 1;
    return parcelEligible && activatedAvailable;
  },
  alternativeSignalSourceActivated(state) {
    const parcelEligible =
      state.selectedParcel && state.selectedParcel.get(window.appConfig.VUE_APP_ACTIVATOR_PROPERTY);
    const activatedAvailable = !!window.appConfig.VUE_APP_SIGNALS_URL_ACTIVATED;
    return parcelEligible && activatedAvailable;
  },
  /**
   * @param { State } state
   * @param { Getters } getters
   * @returns { ImageSource[] }
   */
  activatedImageSources(state, getters, rootState) {
    /** @type { ImageSource[] } */
    const imageSources = rootState.metaInfo.imageSources;
    const isActivated = getters.alternativeWMSSourceActivated;
    return isActivated
      ? imageSources.sort((source) => (source.mask_url ? -1 : 1))
      : imageSources.filter((source) => !source.mask_url);
  },
};

const mutations = {
  SET_SELECTED_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_SELECTED_LAYER(state, layer) {
    state.selectedLayer = layer;
  },
  SET_SELECTED_PARCEL(state, parcel) {
    state.selectedParcel = parcel;
    this._vm.$query.setParams({
      parcel: parcel ? parcel.get("ori_parcel_id") : undefined,
    });
  },
  SET_SELECTED_DATE(state, date) {
    state.selectedDate = date;
    this._vm.$query.setParams({
      date: date || undefined,
    });
  },
  /** @param { State } state
   *  @param { SelectedParcelAvailableImageDates } dates
   */
  SET_SELECTED_PARCEL_AVAILABLE_IMAGE_DATES(state, dates) {
    state.selectedParcelAvailableImageDates = dates;
  },
  SET_SELECTED_REFERENCE_PARCELS(state, referenceParcels) {
    state.selectedReferenceParcels = referenceParcels;
  },
  SET_SELECTED_REFERENCE_AREA(state, referenceAreaGeometry) {
    state.selectedReferenceArea = referenceAreaGeometry;
  },
  SET_SELECTED_PARCEL_FILTER(state, { type, value }) {
    const newFeatureFilter = {
      ...state.selectedParcelFilter,
    };
    if (value !== null) {
      // TODO find more elegant way to check for stringified booleans
      let correctedValue = value;
      if (value === "true" || value === "false") {
        correctedValue = value === "true";
      }
      newFeatureFilter[type] = correctedValue;
    } else {
      delete newFeatureFilter[type];
    }
    state.selectedParcelFilter = newFeatureFilter;
    const params = {};
    params[`filter_${type}`] = value;
    this._vm.$query.setParams(params);
  },
  SET_ALL_MAP_FEATURES(state, features) {
    Vue.set(state, "allMapFeatures", features);
  },
  SET_CURRENT_MAP_FEATURES(state, features) {
    Vue.set(state, "currentMapFeatures", features);
  },
  SET_MAP_LOADING(state, loading) {
    state.mapLoading = loading;
  },
  SET_MARKER_TABLE_LOADING(state, loading) {
    state.markerTableLoading = loading;
  },
  SET_EXPORT_APPROVALS(state, exportApprovals) {
    state.exportApprovals = exportApprovals;
  },
  ADD_EXPORT_APPROVAL(state, newExportApproval) {
    state.exportApprovals.push(newExportApproval);
  },
  SET_CROP_COVER_PERIODS(state, cropCoverPeriods) {
    state.cropCoverPeriods = cropCoverPeriods;
  },
};

const actions = {
  /**
   *  @param {{ state: State, rootState: { metaInfo: StoreMetaInfo? } }}
   *  @param {{ start: DateString, end: DateString}}
   */
  async fetchParcelAvailableImageDates({ state, commit, rootState }, { start, end }) {
    if (!state.selectedParcel) {
      return;
    }
    const declarationId = state.selectedParcel.get("id");
    const isActivatedParcel = !!state.selectedParcel.get(
      window.appConfig.VUE_APP_ACTIVATOR_PROPERTY
    );
    const that = this;
    /**
     * @param { DateString } startDate
     * @param { DateString } endDate
     * @param { ImageSource[] } sources
     * @returns { Promise<SelectedParcelAvailableImageDates> } */
    const extractProducts = async function (startDate, endDate, sources) {
      // eslint-disable-line
      const url = new URL(window.appConfig.VUE_APP_IMAGE_CHIP_CATALOG, window.location);
      url.searchParams.set("start_date", startDate);
      url.searchParams.set("end_date", endDate);
      url.searchParams.set("declaration_id", declarationId);
      sources
        .filter((source) => (isActivatedParcel ? true : !source.mask_url))
        .map((source) => source.id)
        .forEach((source) => {
          url.searchParams.append("sources", source);
        });

      const response = await that._vm.$axios.get(url, {
        responseType: "text",
      });
      const /** @type { import("../../types/Catalog").Catalog } */ catalog = response.data;
      let /** @type { SelectedParcelAvailableImageDates } */ products = {};

      Object.keys(catalog).forEach((source) => {
        products = {
          ...products,
          ...catalog[source].reduce((obj, item) => {
            obj[item.date] = products[item.date] || {};
            obj[item.date][source] = item.cloud_cover;
            return obj;
          }, {}),
        };
      });

      return products;
    };

    const newStart = dayjs(start).subtract(49, "day").format("YYYY-MM-DD");
    const newEnd = dayjs(end).add(49, "day").format("YYYY-MM-DD");
    const sources = rootState.metaInfo.imageSources;
    const auxProducts = await extractProducts(newStart, newEnd, sources);
    let /** @type { SelectedParcelAvailableImageDates } */ products = {
        ...state.selectedParcelAvailableImageDates,
        ...auxProducts,
      };
    const times = Object.keys(products);
    if (
      times.indexOf(state.selectedDate) === 0 ||
      (times.indexOf(state.selectedDate) + 1 === times.length &&
        times.indexOf(state.selectedDate) > 0)
    ) {
      if (times.indexOf(state.selectedDate) === 0) {
        end = start;
        start = dayjs(start).subtract(49, "day").format("YYYY-MM-DD");
      } else {
        start = end;
        end = dayjs(end).add(49, "day").format("YYYY-MM-DD");
      }
      const bufferProducts = await extractProducts(start, end, sources);
      products = {
        ...products,
        ...bufferProducts,
      };
    }
    commit("SET_SELECTED_PARCEL_AVAILABLE_IMAGE_DATES", products);
  },
  /** @param { { state: State }} */
  createDateRange({ state, dispatch }, { date, force = false }) {
    const newDate = Array.isArray(date) ? date[0] : date;
    // check if date is near beginning or end of month
    let start;
    let end;
    const startOfMonth = dayjs(newDate).startOf("month");
    const endOfMonth = dayjs(newDate).endOf("month");
    if (state.selectedParcelAvailableImageDates) {
      if (Object.keys(state.selectedParcelAvailableImageDates).indexOf(newDate) === 0) {
        // fetch current month plus 30 days prior
        start = dayjs(newDate).subtract(49, "day");
        end = endOfMonth;
      } else if (
        Object.keys(state.selectedParcelAvailableImageDates).indexOf(newDate) + 1 ===
          Object.keys(state.selectedParcelAvailableImageDates).length &&
        Object.keys(state.selectedParcelAvailableImageDates).indexOf(newDate) > 0
      ) {
        // fetch current month plus 30 days after
        start = startOfMonth;
        end = dayjs(newDate).add(49, "day");
      } else {
        // fetch current month
        start = startOfMonth;
        end = endOfMonth;
      }
    } else {
      // fetch current month
      start = startOfMonth;
      end = endOfMonth;
    }

    start = start.format("YYYY-MM-DD");
    end = end.format("YYYY-MM-DD");
    // only fetch dates if dates not yet present in availableDates
    if (
      force ||
      !state.selectedParcelAvailableImageDates ||
      !Object.keys(state.selectedParcelAvailableImageDates).includes(start) ||
      !Object.keys(state.selectedParcelAvailableImageDates).includes(end)
    ) {
      dispatch("fetchParcelAvailableImageDates", {
        start,
        end,
      });
    }
  },
  selectParcel({ state, dispatch, commit }, parcel) {
    commit("SET_SELECTED_PARCEL_AVAILABLE_IMAGE_DATES", {});
    commit("SET_SELECTED_PARCEL", parcel);
    if (parcel) {
      const date = state.selectedDate || new Date();
      dispatch("createDateRange", {
        date,
        force: true,
      });
    }
  },
  selectDate({ dispatch, commit }, date) {
    if (date !== null) {
      if (Array.isArray(date)) {
        commit("SET_SELECTED_DATE", date);
      } else {
        commit("SET_SELECTED_DATE", dayjs(date).format("YYYY-MM-DD"));
      }
      dispatch("createDateRange", {
        date,
      });
    }
  },
  checkQuery({ dispatch, commit }) {
    if (this._vm.$query.getParam("parcel")) {
      dispatch("searchParcel", this._vm.$query.getParam("parcel"));
    }
    if (this._vm.$query.getParam("layer")) {
      dispatch("selectLayer", this._vm.$query.getParam("layer"));
    }
    if (this._vm.$query.getParam("date")) {
      commit("SET_SELECTED_DATE", this._vm.$query.getParam("date"));
    }
    dispatch("checkFilterQuery", this._vm.$query.getQuery());
  },
  searchParcel(_, payload) {
    return payload;
  },
  selectLayer(_, payload) {
    return payload;
  },
  checkFilterQuery(_, payload) {
    return payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
