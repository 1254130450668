import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store"; // eslint-disable-line

import Passthrough from "../views/PassthroughView.vue";
import { availableProducts } from "../helpers/envVars";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    redirect: `/${availableProducts[0]}/parcelexplorer`,
  },
  {
    // dummy redirection to reuse default redirection with "parcelexplorer"
    path: "/drought_explorer/parcelexplorer",
    redirect: "/drought_explorer",
  },
  {
    path: "/drought_explorer",
    name: "drought-explorer-container",
    component: () =>
      import(/* webpackChunkName: "drought_explorer" */ "../views/DroughtExplorerContainer.vue"),
  },
  {
    path: "/drought_explorer_left",
    name: "drought-explorer-left",
    nav: false,
    icon: "mdi-map-search-outline",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/drought_explorer_right",
    name: "drought-explorer-right",
    nav: false,
    icon: "mdi-map-search-outline",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/crop_type_classification",
    name: "crop_type_classification",
    nav: true,
    component: Passthrough,
    icon: "mdi-head-lightbulb",
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
      ...(window.appConfig.VUE_APP_EXTERNAL_DASHBOARD
        ? [
            {
              path: "qualityassessment",
              name: "quality-assessment",
              icon: "mdi-beaker-question",
              component: () =>
                import(
                  /* webpackChunkName: "externaldashboard" */ "../views/ExternalDashboard.vue"
                ),
            },
          ]
        : []),
    ],
  },
  {
    path: "/mowing_event_detection",
    name: "mowing_event_detection",
    nav: true,
    icon: "mdi-head-lightbulb",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
      ...(window.appConfig.VUE_APP_EXTERNAL_DASHBOARD
        ? [
            {
              path: "qualityassessment",
              name: "quality-assessment",
              icon: "mdi-beaker-question",
              component: () =>
                import(
                  /* webpackChunkName: "externaldashboard" */ "../views/ExternalDashboard.vue"
                ),
            },
          ]
        : []),
      ...(window.appConfig.VUE_APP_EXTERNAL_DASHBOARD
        ? [
            {
              path: "report",
              name: "report",
              icon: "mdi-file-chart",
              component: () =>
                import(
                  /* webpackChunkName: "externaldashboard" */ "../views/ExternalDashboard.vue"
                ),
            },
          ]
        : []),
    ],
  },
  {
    path: "/harvest_detection",
    name: "harvest_detection",
    nav: true,
    icon: "mdi-head-lightbulb",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
      ...(window.appConfig.VUE_APP_EXTERNAL_DASHBOARD
        ? [
            {
              path: "report",
              name: "report",
              icon: "mdi-file-chart",
              component: () =>
                import(
                  /* webpackChunkName: "externaldashboard" */ "../views/ExternalDashboard.vue"
                ),
            },
          ]
        : []),
    ],
  },
  {
    path: "/fallow_land",
    name: "fallow_land",
    nav: true,
    icon: "mdi-head-lightbulb",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/crop_cover",
    name: "crop_cover",
    nav: true,
    component: Passthrough,
    icon: "mdi-head-lightbulb",
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/winter_catch_crops",
    name: "winter_catch_crops",
    nav: true,
    icon: "mdi-head-lightbulb",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
      ...(window.appConfig.VUE_APP_EXTERNAL_DASHBOARD
        ? [
            {
              path: "report",
              name: "report",
              icon: "mdi-file-chart",
              component: () =>
                import(
                  /* webpackChunkName: "externaldashboard" */ "../views/ExternalDashboard.vue"
                ),
            },
          ]
        : []),
    ],
  },
  {
    path: "/permanent_green_cover",
    name: "permanent_green_cover",
    nav: true,
    icon: "mdi-head-lightbulb",
    component: Passthrough,
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/homogeneity",
    name: "homogeneity",
    nav: true,
    component: Passthrough,
    icon: "mdi-head-lightbulb",
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/imperviousness",
    name: "imperviousness",
    nav: true,
    component: Passthrough,
    icon: "mdi-head-lightbulb",
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  {
    path: "/land_cover_change",
    name: "land_cover_change",
    nav: true,
    component: Passthrough,
    icon: "mdi-head-lightbulb",
    children: [
      {
        path: "parcelexplorer",
        name: "parcel-explorer",
        icon: "mdi-map-search-outline",
        component: () =>
          import(/* webpackChunkName: "parcelexplorer" */ "../views/ParcelExplorer.vue"),
      },
    ],
  },
  ...(!window.appConfig.VUE_APP_ADMINISTRATION_FEATURES ||
  window.appConfig.VUE_APP_ADMINISTRATION_FEATURES !== "false"
    ? [
        {
          path: "/administration",
          name: "administration",
          nav: true,
          icon: "mdi-tune-vertical-variant",
          component: Passthrough,
          children: [
            {
              path: "ctgroups",
              name: "crop-type-groups",
              icon: "mdi-table-cog",
              component: () =>
                import(/* webpackChunkName: "ctgroups" */ "../views/CropTypeGroups.vue"),
            },
            {
              path: "exports",
              name: "data-exports",
              icon: "mdi-database-export",
              component: () => import(/* webpackChunkName: "export" */ "../views/DataExports.vue"),
            },
            {
              path: "report",
              name: "report",
              icon: "mdi-file-chart",
              component: () => import(/* webpackChunkName: "report" */ "../views/ReportView.vue"),
              beforeEnter: async function (to, from, next) {
                if (!store.state.metaInfo) await store.dispatch("fetchMetaInfo");
                return next();
              },
            },
          ].filter((r) => {
            return window.appConfig.VUE_APP_ADMINISTRATION_FEATURES
              ? window.appConfig.VUE_APP_ADMINISTRATION_FEATURES.split(", ").includes(r.path)
              : true;
          }),
        },
      ]
    : []),
  {
    path: "/check",
    name: "check",
    component: () => import(/* webpackChunkName: "check" */ "../views/BackendConnectionCheck.vue"),
  },
  {
    path: "*",
    name: "notFound",
    component: () => import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

const filteredRoutes = routes.filter((route) => {
  if (Object.hasOwn(route, "children") && route.children[0].path === "parcelexplorer") {
    return availableProducts.find((p) => route.path.includes(p));
  } else {
    return route;
  }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: filteredRoutes,
});

export default router;
