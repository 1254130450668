import router from "../router";
import debounce from "./debounce";

const getQuery = () => router.currentRoute.query;
const getParam = (param) => router.currentRoute.query[param];

const setParams = (paramObj) => {
  const currentQuery = router.currentRoute.query;
  debounce(
    () =>
      router
        .replace({
          query: {
            ...currentQuery,
            ...paramObj,
          },
        })
        .catch(() => {}),
    400
  );
};

// https://stackoverflow.com/a/52539264
const groupParamsByKey = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (acc[key]) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});

export default {
  getQuery,
  getParam,
  setParams,
  groupParamsByKey,
};
