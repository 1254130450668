import axios from "axios";
import store from "../store";

export default function axiosInstance() {
  const instance = axios.create();
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // special case for eo-signals-api, where a 404 error means that no signal is present
      // (which is expected for some parcels)
      const specialCase = error.config?.url.includes("/eo-signals-api/");
      store.commit("sendAlert", {
        message: specialCase ? "i18n:no-signal-available" : error.message,
        type: error.response?.status < 500 ? "info" : "error",
      });
      return Promise.reject(error);
    }
  );
  return instance;
}
