<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
    dark
    style="z-index: 19"
  >
    <v-app-bar-nav-icon v-if="!hideMenu" @click.stop="toggleDrawer" />
    <v-toolbar-title>
      {{ $t($store.state.parcels.selectedProduct) }} | {{ $store.state.appName }}
    </v-toolbar-title>
    <v-spacer />
    <v-menu v-if="userInfo" transition="slide-x-transition" bottom left offset-y>
      <template #activator="{ on }">
        <v-btn
          :text="$vuetify.breakpoint.smAndUp"
          :icon="$vuetify.breakpoint.xsOnly"
          class="text-none"
          v-on="on"
        >
          <span v-if="$vuetify.breakpoint.smAndUp">{{ userInfo.email }}</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp" color="white"> mdi-dots-vertical </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item width="200">
          <v-list-item-avatar class="my-0">
            <v-icon> mdi-account-circle </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="d-block">
            <v-list-item-title>{{ userInfo.user }}</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :href="logoutUrl">
          <v-list-item-avatar class="my-0">
            <v-icon> mdi-logout-variant </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            {{ $t("logout") }}
          </v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-list-item-avatar class="my-0">
            <v-icon> mdi-information-outline </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            {{ $t("app-version") }} : {{ $store.state.appVersion }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: () => ({
        user: "demo",
        email: "demo@eox.at",
      }),
    },
  },
  data: () => ({
    logoutUrl: window.appConfig.VUE_APP_LOGOUT_URL,
  }),
  computed: {
    hideMenu() {
      return window.appConfig.VUE_APP_HIDE_MENU === "true";
    },
  },
  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
  },
};
</script>
