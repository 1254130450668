let timeout;

const clear = () => {
  clearTimeout(timeout);
  timeout = null;
};
const set = (wait) => setTimeout(clear, wait);

export default function debounce(fn, wait = 1000) {
  if (!timeout) {
    timeout = set(wait);
    fn();
  }
}
