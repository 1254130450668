<template>
  <v-app>
    <app-header v-if="!hideMenu" :user-info="userInfo" @toggleDrawer="drawer = !drawer" />
    <v-navigation-drawer
      v-if="!hideMenu"
      v-model="drawer"
      app
      clipped
      temporary
      style="z-index: 20"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $store.state.appName }}
          </v-list-item-title>
          <details v-if="metaInfo">
            <summary>{{ $t("configuration") }}</summary>

            <v-list-item-subtitle
              v-for="(config, key) in {
                ...parcelConfig,
                'thresholds-etc': metaInfo.annotationParameters,
              }"
              :key="key"
            >
              <template v-if="typeof config === 'string'">
                <strong>{{ $t(`${key}`) }}: </strong>{{ config }}
              </template>
              <template v-else>
                <strong>{{ $t(`${key}`) }}:</strong>
                <ul>
                  <li
                    v-for="[configKey, configValue] of Object.entries(config)"
                    :key="configKey"
                    style="word-break: break-all; white-space: pre-line"
                  >
                    {{ $t(`${configKey.replace("_model_name", "")}`) }}:
                    {{ configValue }}
                  </li>
                </ul>
              </template>
            </v-list-item-subtitle>
          </details>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list dense nav>
        <v-list-group
          v-for="item in availableItems"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          :data-cy="item.path.replace('/', '')"
        >
          <template #activator>
            <v-list-item-title>{{ $t(`${item.name}`) }}</v-list-item-title>
          </template>
          <v-list-item
            v-for="subItem in item.children"
            :key="subItem.name"
            :to="{ path: item.path + '/' + subItem.path }"
            link
            class="pl-5"
            :data-cy="item.path.replace('/', '') + '-' + subItem.path"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(`${subItem.name}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <template v-if="!hideAdministration">
          <v-divider class="my-3" />

          <v-list-group
            v-for="item in navItems.filter((i) => i.path === '/administration')"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.icon"
            :disabled="item.disabled"
          >
            <template #activator>
              <v-list-item-title>{{ $t(`${item.name}`) }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="subItem in item.children"
              :key="subItem.name"
              :to="{ path: item.path + '/' + subItem.path }"
              link
              class="pl-5"
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(`${subItem.name}`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>

        <v-divider class="my-3" />

        <v-list-group prepend-icon="mdi-cog">
          <template #activator>
            <v-list-item-title>{{ $t(`settings`) }}</v-list-item-title>
          </template>
          <v-list-item class="pl-5">
            <v-list-item-icon>
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <LanguageSelector />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="fill-height">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { routes } from "./router";
import AppHeader from "./components/AppHeader.vue";
import LanguageSelector from "./components/LanguageSelector.vue";
import { availableProducts } from "./helpers/envVars";

export default {
  name: "App",
  components: {
    AppHeader,
    LanguageSelector,
  },
  data: () => ({
    drawer: true,
    oauthCookieInterval: null,
    userInfo: null,
    // TODO remove with i18n
  }),
  computed: {
    ...mapState(["parcelConfig"]),
    ...mapState(["metaInfo"]),
    navItems() {
      return routes.filter((i) => i.nav);
    },
    hideMenu() {
      return window.appConfig.VUE_APP_HIDE_MENU === "true";
    },
    hideAdministration() {
      return window.appConfig.VUE_APP_ADMINISTRATION_FEATURES === "false";
    },
    availableItems() {
      return this.navItems.filter((item) => {
        return (
          item.path !== "/administration" && availableProducts.find((p) => item.path.includes(p))
        );
      });
    },
  },
  watch: {
    $route(to) {
      if (to.matched.length > 1) {
        const parent = to.matched.filter((m) => !m.parent)[0];
        const curRoute = routes.filter((route) => route.name === parent.name)[0];
        curRoute.active = true;
        this.SET_SELECTED_PRODUCT(curRoute.path.split("/")[1]);
      }
    },
  },
  created() {
    document.title = this.$store.state.appName;
  },
  mounted() {
    this.fetchMetaInfo();
    if (process.env.NODE_ENV !== "development") {
      this.checkOauthCookie();
      this.oauthCookieInterval = setInterval(() => {
        this.checkOauthCookie();
      }, 60000);
    } else {
      this.userInfo = {
        user: "localhost",
        email: "localhost@eox.at",
      };
    }
  },

  methods: {
    ...mapMutations("parcels", ["SET_SELECTED_PRODUCT"]),
    ...mapActions(["fetchMetaInfo"]),
    async checkOauthCookie() {
      const response = await this.$axios.get(window.appConfig.VUE_APP_USERINFO_URL);
      if (response.status === 200) {
        if (typeof response.data === "object" && "email" in response.data) {
          this.userInfo = response.data;
        } else {
          this.userInfo = {
            user: "demo",
            email: "demo@eox.at",
          };
        }
      } else if (response.status === 302) {
        this.$store.commit("sendAlert", {
          message: "Session expired, redirecting...",
          type: "error",
        });
        setTimeout(() => {
          window.location.href = response.headers.location;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
:deep(.v-list-item__icon) {
  margin-right: 10px !important;
}
:deep(.v-list-group__header__prepend-icon) {
  margin-right: 10px !important;
}
:deep(.v-list-group__header__append-icon) {
  margin: 0 !important;
}
:deep(.v-list-group--disabled) {
  pointer-events: none;
  opacity: 0.5;
}
</style>
